import { useState } from 'react'
import { SetState } from 'types/react'
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd'
import { Table, TableContainer } from '@mui/material'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'

import {
    MultipleTableBasedOnCells, MultipleTableFieldCells, MultipleTableHeader,
    MultipleTableRowActions, MultipleTableRowSelectCell
} from 'components/form/fields/multiple'
import { useFieldInfo } from 'contexts/FieldInfoContext'
import { fallback } from 'utils/utils'
import { MultipleRows, MultipleSelected } from 'types/multiple'
import { MultipleFeatures, MutableMultipleProps } from '../InputMultipleField'

export type MultipleRowProps = {
  features:    MultipleFeatures,
  rows:        MultipleRows  
  selected:    MultipleSelected
  setSelected: SetState<MultipleSelected>
}

const MultipleTable = ({ features }: MutableMultipleProps) => {
  const { info, augProps, fieldProps } = useFieldInfo()
  const [ selected, setSelected ] = useState<MultipleSelected>([])
  const rows: MultipleRows = fallback(fieldProps.value, [{}])

  const onDragAndDrop = (result: any) => {
    if (!result.destination) {
      return;
    }

    const from = result.source.index
    const to   = result.destination.index
   
    const newRows = moveDragRow(rows, from, to)
    augProps.setValue(newRows)
  }

  return (
    <TableContainer sx={{ maxHeight: 'calc(100vh - 120px)', minWidth: '100%' , overflow: 'auto'}}>
      <Table size="small" aria-label="simple table" id={fieldProps.id}>
        <MultipleTableHeader features={features} rows={rows} selected={selected} setSelected={setSelected}/>
        
        <DragDropContext onDragEnd={onDragAndDrop}>
          <Droppable droppableId={`drop-${info.rpath}`}>
            {(provided: any, _snapshot: any) => (
              <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                {rows.map((_, index) => (
                  <Draggable key={index} index={index} draggableId={"q-" + index} isDragDisabled={!features.reorder}>
                    {(provided: any, snapshot: any) => (
                      <TableRow
                        hover
                        selected={selected.includes(index)}

                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...{...provided.dragHandleProps, "tabIndex": -1}}
                        
                        style={getDragRowStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <MultipleTableRowSelectCell index={index} features={features} selected={selected} setSelected={setSelected} />
                        <MultipleTableBasedOnCells  index={index} rows={rows} />
                        <MultipleTableFieldCells    index={index} />
                        <MultipleTableRowActions    index={index} rows={rows} features={features} selected={selected} setSelected={setSelected} />
                      </TableRow>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </TableBody>
            )}
          </Droppable>
        </DragDropContext>
      </Table>
    </TableContainer>
  )
}

export function moveDragRow(list: any[], fromIndex: number, toIndex: number) {
  const result = Array.from(list);
  const [removed] = result.splice(fromIndex, 1);
  result.splice(toIndex, 0, removed);

  return result;
};

export function getDragRowStyle(isDragging: boolean, draggableStyle: React.CSSProperties): React.CSSProperties {
  return {
    background: isDragging ? "grey" : "white",
    ...draggableStyle
  }
}

export default MultipleTable
