import { createContext }       from 'react';
import merge                   from 'deepmerge'
import { useFormInfo }         from 'hooks/form';
import { 
  toValuesPath, 
  collectDefaults, 
  enrichValues,
  collectAdditionalValues }              from 'components/form/utils/field-utils';
import { getPath, pruneEmpty } from 'utils/utils';

export const ValuesContext = createContext();

const ValuesProvider = ({children}) => {
  const formInfo = useFormInfo()

  function createValues(vpath = "", withSaved = true, useDynamicDefault = true) {
    const valuesPath     = toValuesPath(formInfo, vpath)
    const defaultValues  = collectDefaults(getPath(formInfo.form.values, valuesPath), valuesPath, formInfo, useDynamicDefault)
    const savedValues    = withSaved ? getPath(formInfo.form.saved, vpath, {}) : {}
    const prunedValues   = pruneEmpty(defaultValues)
    const prefillValues  = formInfo.kind === "PROCESS_FILL" && !vpath ? getPath(formInfo.prefill, valuesPath, {}) : {}
    const valueList      = [defaultValues, prunedValues, prefillValues, savedValues]

    // based-ons and such
    const lookupValues = getPath(formInfo.form.values, vpath, {})
    const additionalValues = collectAdditionalValues(Array.isArray(lookupValues) ? lookupValues[0] : lookupValues)
    const values = enrichValues(merge.all(valueList, { arrayMerge: overwriteMerge }), additionalValues)

    console.debug("ValuesProvider.createValues: vpath=%o, lookupValues=%o, defaultValues=%o, prunedValues=%o, prefillValues=%o, savedValues=%o, result=%o", 
      vpath, lookupValues, defaultValues, prunedValues, prefillValues, savedValues, values)

    return values
  }

  const values = createValues()

  return (
    <ValuesContext.Provider value={{ values, createValues }}>
      {children}
    </ValuesContext.Provider>
  )
}

const overwriteMerge = (_destinationArray, sourceArray, _options) => sourceArray

export default ValuesProvider;
