import humanizeDuration            from 'humanize-duration'
import { getInitialLocale }        from 'contexts/utils/localization-utils'
import { t }                       from 'utils/i18n'
import { env }                     from 'utils/utils'
import moment, * as momentHelpers  from 'helpers/moment'

export interface DisplayProps {
  language?: string // TODO kunnen language en locale verplicht worden?
  locale?:   string
  type?:     string
  format?:   string
}

interface Field {
  key:       string
  label:     string
  type?:     string
  format?:   string
}

const defaultDateFormat     = env('DEFAULT_FORMAT_DATE')     || 'dd MMM yyyy'
const defaultTimeFormat     = env('DEFAULT_FORMAT_TIME')     || 'HH:mm'
const defaultDateTimeFormat = env('DEFAULT_FORMAT_DATETIME') || 'dd MMM yyyy HH:mm'
const defaultNumberOptions  = { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: undefined }

export const toDisplayValue = (value: any, props?: DisplayProps): string | number => {
  //console.log("toDisplayValue: value=%o, props=%o", value, props)
  switch (true) {
    case typeof value == 'boolean' || props?.type == 'boolean': {
      return t(`values.${value}`) as string; /* i18next-extract-disable-line */
    }

    case typeof value == 'number' || props?.type == 'number': {
      if (Number.isSafeInteger(value)) {
        return value
      }
      else {
        const locale = getLocale(props)
        const formatter = new Intl.NumberFormat(locale, defaultNumberOptions)
        return formatter.format(value)
      }
    }

    case props?.type == 'date': {
      const format = props?.format || defaultDateFormat
      const m = momentHelpers.parseDate(value)
      return m.formatJavaDTF(format)
    }
    
    case props?.type == 'time': {
      const format = props?.format || defaultTimeFormat
      const m = momentHelpers.parseTime(value)
      return m.formatJavaDTF(format)
    }

    case props?.type == 'datetime': {
      const format = props?.format || defaultDateTimeFormat
      const m = momentHelpers.parseIsoDatetime(value)
      return m.formatJavaDTF(format)
    }

    case typeof value == 'string': {
      return interpretString(value as string, props?.language)
    }
  
    default: {
      return JSON.stringify(value);
    }
  }
}

export const interpretString = (value: string, language?: string) => {
  // const dateTimeMoment = momentHelpers.parseDatetime(value)
  // if (dateTimeMoment.isValid())
  //   return dateTimeMoment.formatJavaDTF(defaultDateTimeFormat)
  
  // const dateMoment = momentHelpers.parseDate(value)
  // if (dateMoment.isValid()) 
  //   return dateMoment.formatJavaDTF(defaultDateFormat)

  // const timeMoment = momentHelpers.parseTime(value)
  // if (timeMoment.isValid())
  //   return timeMoment.formatJavaDTF(defaultTimeFormat)

  if (/^(-?)P(?=\d|T\d)(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)([DW]))?(?:T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+(?:\.\d+)?)S)?)?$/.test(value)) 
    return durationToHumanReadable(value, language)!

  return value
}

export function getDisplayProps(field: Field): DisplayProps {
  if (field.type) {
    return { type: field.type, format: field.format }
  }
  return {}
}

export function getLocale(props?: DisplayProps) {
  // TODO gekke constructie... param van getInitialLocale kan alleen undefined zijn
  const locale = props?.locale || getInitialLocale(props?.locale)
  return locale?.replace("_", "-")
}

const shortLanguages = {
  languages: {
    enShort: {
      y: () => "y",
      mo: () => "mo",
      w: () => "wk",
      d: () => "d",
      h: () => "h",
      m: () => "min",
      s: () => "s",
      ms: () => "ms",
    },
    nlShort: {
      y: () => "jr",
      mo: () => "mnd",
      w: () => "wk",
      d: () => "d",
      h: () => "u",
      m: () => "min",
      s: () => "s",
      ms: () => "ms",
    },
  }
}

const shortHumanizer = humanizeDuration.humanizer(shortLanguages)

function humanize(millis: number, options?: any) {
  const lang     = options?.language
  const language = lang ? {language: `${lang}Short`, fallback: [lang, 'en']} : {}
  const props    = {
    decimals: lang ? lang == "en" ? "," : "." : ".",
    delimiter: " ",
    // conjunction: t("descriptions.task.and"),
    // serialComma: false
  }
  
  return shortHumanizer(millis, {...options, ...language, ...props})
}

function durationToHumanReadable(duration: string, language?: string) {
  const m = moment.duration(duration)
  return m.isValid() ? humanize(m.asMilliseconds(), {language}) : undefined
}
