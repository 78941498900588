import React from 'react';
import { getValues } from 'components/form/utils/field-utils';
import { Content, PartialContentOptions } from 'helpers/content';
import { TraitHelper } from 'helpers/traits';
import { Field } from 'types/graphql';
import { ThemeIcon } from 'types/theming';

import { FileRef, FileRefs } from './fileref';

class Types {
  static asFileRef  = (value: any): FileRef  => Types.isFileRef(value)  ? value as FileRef  : Types.null<FileRef>()
  static asFileRefs = (value: any): FileRefs => Types.isFileRefs(value) ? value as FileRefs : Types.null<FileRefs>()

  static toContent = (value: any, options?: PartialContentOptions): Content => Content.from(value, options)

  static as<T>(value: any): T { return value as T }
  static null<T>(): T { return null as unknown as T }

  static isObject = (value: any): boolean => value !== null && typeof value === 'object' && !Array.isArray(value) 
  static asObject  = (value: any): object => Types.isObject(value) ? value : {}
  static isOption  = (value: any): boolean => Types.isObject(value) && value.hasOwnProperty('value') && value.hasOwnProperty('label') && !value.hasOwnProperty('__kind')

  static isSubmitField  = (formInfo: any, field: Field): boolean => {
    if (!TraitHelper.hasTrait(field, 'submit') )
      return false

    const path       = field.path
    const inMultiple = String(path).includes('.')
    
    if (inMultiple) {
      console.error("Field %o has submit trait, but is inside a multiple", field.name)
      return false
    }

    if (field.type.includes("MULTIPLE")) {
      console.error("Field %o has submit trait, but is a multiple type", field.name)
      return false
    }

    if (field.type == "ENTITY" || field.type == "SELECT")
      return true

    const hasOptions = field.hasChoices

    if (hasOptions)
      return true
    else {
      console.error("Field %o has submit trait, but has no options", field.name)
      return false
    }
  }

  static isContents     = (value: any): boolean => Array.isArray(value) && value.length      != 0 && Types.isContent(value[0])
  static isContent      = (value: any): boolean => value instanceof Content
  static isGearsObj     = (value: any): boolean => Types.isObject(value) && value.hasOwnProperty('__kind')
  static isGearsType    = (value: any): boolean => Types.isGearsObj(value) || Types.isFileRefs(value)
  static isFileName     = (value: any): boolean => Types.isGearsType(value) && value.__kind === 'filename';
  static isFileRef      = (value: any): boolean => Types.isGearsType(value) && (value.__kind === "content-ref" || value.__kind === "ContentRef");
  static isTuple        = (value: any): boolean => Types.isGearsType(value) && value.__kind === 'tuple';
  static isEntity       = (value: any): boolean => Types.isGearsType(value) && value.__kind === 'entity';
  static isDisplay      = (value: any): boolean => Types.isGearsType(value) && value.__kind === 'display';
  static isHTML         = (value: any): boolean => Types.isDisplay(value) && value.__format  == 'html';
  static isFileRefs     = (value: any): boolean => Array.isArray(value) && value.length > 0 && Types.isFileRef(value[0]);
  static isComponent    = (value: any): boolean => React.isValidElement(value) || Array.isArray(value) && value.length > 0 && React.isValidElement(value[0]);
  static isPeriodObject = (value: any): boolean => Types.isObject(value) && value.hasOwnProperty('period') && value.hasOwnProperty('units') && value.hasOwnProperty('duration');
  static isStructure    = (value: any): boolean => Types.isObject(value) || Array.isArray(value);
  static isTable        = (value: any): boolean => (Types.isStructure(value) && !Types.isGearsType(value)) || Types.isTuple(value)                                                // this leads to a table rendering
  static isSimpleType   = (value: any): boolean => ['string', 'number', 'boolean'].includes(typeof value);
  static isValidDate    = (value: any): boolean => value instanceof Date && !isNaN(value.getTime())
}

export type Primitive = bigint | boolean | null | number | string | symbol | undefined

export type GearsKind = "filename" | "content-ref" | "ContentRef" | "tuple" | "entity" | "display" | "icon" 

export type GearsKindType = {
  __kind: GearsKind
}

export interface GearsIconType extends GearsKindType, ThemeIcon {
  __kind :   "icon"
}

export interface GearsTuple extends GearsKindType {
  __kind:        'tuple'
  [key: string]: GearsValue | Primitive
}

export interface GearsFileRef extends GearsKindType {
  __kind:      "content-ref" | "ContentRef"
  __id:        string
  filename:    string
  contentType: string
}

export interface GearsHtml extends GearsDisplay {
  __format: "html"
}

export interface GearsEntity extends GearsKindType {
  __kind: "entity"
  __text: string
}

export interface GearsValueType extends GearsKindType {
  __value: any
}

export interface GearsFilename extends GearsValueType {
  __kind: "filename"
  __value: string
}

export interface GearsDisplay extends GearsValueType {
  __kind:  "display"
  __format: string
  __value: string
}

export type GearsValue = GearsFilename | GearsHtml | GearsEntity | GearsTuple | GearsDisplay | GearsHtml | GearsValueType

export type SubmitValues = {
  [variable: string]: any
}

export default Types
